(($) => {
  const galleries = document.querySelectorAll('.gallery-carousel');

  galleries.forEach(gallery => {
    const lookupItemById = id => {
      const i = items.findIndex(item => item.dataset.id === id);
      return [items[i],i];
    }

    const setActiveItem = index => {
      gallery.classList.remove(`active-${activeIndex ? activeIndex + 1 : 1}`);
      if (activeIndex !== undefined && activeIndex !== 0 && index+1 === 1) {
        gallery.classList.add("active-reset");
        setTimeout(() => {
          gallery.classList.remove("active-reset");
        },3000);
      }
      activeIndex = index;
      gallery.classList.add(`active-${ activeIndex + 1}`);
      gallery.dispatchEvent(new CustomEvent("carouselActiveChanged", {
        detail: {
          activeItem: activeIndex
        }
      }));
    }

    const setHandlers = () => {
      items.forEach(item => {
        const trigger = item.querySelector('.gallery__item__factoid__inner');
        trigger.addEventListener("mouseover",() => {
          if (autoCycleInterval) clearInterval(autoCycleInterval);
          setActiveItem(lookupItemById(item.dataset.id)[1]);
        });
        trigger.addEventListener("click",() => {
          window.location.href = item.dataset.link;
        });
      })

      gallery.addEventListener("galleryActiveChanged", e => {
        if (autoCycleInterval) clearInterval(autoCycleInterval);
        const index = e.detail.activeItem;
        if (index !== activeIndex) setActiveItem(index);
      });
    }

    const postType = gallery.dataset.postType;
    const postId = gallery.dataset.postId;
    const items = Array.from(gallery.querySelectorAll('.gallery__item'));
    let activeIndex, autoCycleInterval;
    const currentItem = lookupItemById(postId);

    setActiveItem((currentItem && currentItem[0]) ? currentItem[1] : 0);
    setHandlers();

    if (gallery.classList.contains('auto-cycle')) {

      let i = activeIndex;
      const limit = items.length;

      autoCycleInterval = setInterval(() => {
        i++;
        if (i >= limit) i = 0;
        setActiveItem(i);
      },6000);

    }


  })

})(jQuery)